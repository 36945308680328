import { Icon } from "@subframe/core";
import { Path } from "app/path";
import { DualPricingPaymentTypes } from "app/payment/usePayment";
import { SaleSliceState } from "app/redux/saleSlice";
import { Header, PaymentModalHeader, PoweredBy } from "app/subframe";
import { DualButton } from "app/subframe/components/DualButton";
import { round } from "lodash";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";

export interface ChoosePaymentMethodProps {
  paymentLinkInfo: any;
  setShowPaymentMethods: React.Dispatch<React.SetStateAction<boolean>>;
  cardSubtotal: string;
  cashSubtotal: string;
  token: string;
  sale: SaleSliceState;
  hasPaidAndUnpaidMerchantServicesWithDualPricing: boolean;
}

export const ChoosePaymentMethod: React.FC<ChoosePaymentMethodProps> = ({
  paymentLinkInfo,
  setShowPaymentMethods,
  cardSubtotal,
  cashSubtotal,
  token,
  sale,
  hasPaidAndUnpaidMerchantServicesWithDualPricing,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Header
        className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
        image={paymentLinkInfo.companyLogo}
        text={paymentLinkInfo.companyName}
      />
      <div className="flex w-112 flex-col items-center gap-1 mobile:h-auto mobile:w-full mobile:flex-none mobile:py-0 mobile:px-2">
        <div className="flex w-full items-center gap-4">
          <span className="grow shrink-0 basis-0 font-['Inter'] text-[18px] font-[500] leading-[28px] text-neutral-900">
            {paymentLinkInfo.companyName} offers cash discount
          </span>
        </div>
        <span className="w-full text-body font-body text-neutral-700">
          Choose a payment method
        </span>
      </div>
      <div className="flex w-112 relative grow shrink-0 basis-0 flex-col items-center gap-6 rounded-lg bg-white mobile:w-full">
        {hasPaidAndUnpaidMerchantServicesWithDualPricing && (
          <div className="flex w-full flex-col items-start gap-2 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
            <div className="flex flex-col gap-2">
              <div className="flex w-full items-center gap-2">
                <Icon
                  className="text-body font-body text-warning-500"
                  name="FeatherAlertTriangle"
                />
                <p className="text-sm font-[600] leading-[20px] text-neutral-900">
                  Update to this transaction
                </p>
              </div>

              <p className="text-sm font-[400] leading-[20px] text-neutral-500 mb-2">
                There is a new balance on this transaction that has been
                partially paid. Due to your dual pricing settings, the previous
                payment for this transaction will be voided when you proceed
                with checkout. A new subtotal will be calculated.
              </p>
            </div>
          </div>
        )}
        <div className="flex w-full flex-col items-center gap-10 mobile:pt-20 mobile:px-2">
          <DualButton
            icon="FeatherCreditCard"
            payType="Pay by Card"
            price={cardSubtotal}
            text3=""
            onClick={() => setShowPaymentMethods(false)}
          />
          <DualButton
            icon="FeatherBanknote"
            payType="Pay by Cash"
            price={cashSubtotal}
            text3="(Cash Discount)"
            variant="cash"
            onClick={() =>
              navigate(
                generatePath(Path.PAYMENT_DUAL_PRICING, {
                  token,
                  method: DualPricingPaymentTypes.CASH,
                })
              )
            }
          />
        </div>
        <div className="absolute bottom-3 flex w-full grow shrink-0 basis-0 flex-col items-center justify-end gap-4">
          <PoweredBy text="Powered by" />
        </div>
      </div>
    </>
  );
};
